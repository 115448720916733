































































































import { Component, Vue, Watch, Prop } from 'vue-property-decorator';
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import {
  InviteUserFormValues,
  Product
} from '../../../store/modules/application/types/application.types';
import { isEmail } from '../../../jbi-shared/util/validate-email-domains.util';

@Component({
  components: {
    ValidationProvider,
    ValidationObserver
  }
})
export default class InviteNewUserForm extends Vue {
  @Prop() public products!: Product[];
  public form: InviteUserFormValues = {
    email: '',
    firstName: '',
    lastName: ''
  };

  @Watch('form', { deep: true })
  public watchFormValues() {
    if (
      this.form.email &&
      isEmail(this.form.email) &&
      this.form.firstName &&
      this.form.lastName
    ) {
      this.$emit('changeDisabledButton', false);
      this.$emit('formData', this.form);
    } else {
      this.$emit('changeDisabledButton', true);
    }
  }
}
