var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"modal-card has-modal-large",attrs:{"id":"assign-to-role-modal"}},[_c('header',{staticClass:"modal-card-head"},[_c('p',{staticClass:"modal-card-title"},[_vm._v(" "+_vm._s(_vm.stepsIndex === 1 ? 'Invite new user' : 'Assign to role')+" ")]),_c('button',{staticClass:"close-icon",attrs:{"type":"button"},on:{"click":_vm.closeModal}},[_c('i',{staticClass:"mdi mdi-close"})])]),_c('section',{class:['modal-card-body', _vm.$style.noPadding]},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.stepsIndex === 0),expression:"stepsIndex === 0"}],class:[_vm.$style.width100, _vm.$style.container]},[_c('div',{class:_vm.$style.width100},[_c('label',[_vm._v(" Role ")]),_c('div',{class:_vm.$style.itemContainer},[_c('p',[_vm._v(_vm._s(_vm.role.name))])])]),_c('div',{class:_vm.$style.optionsContainer},[(!_vm.resourceType)?_c('b-field',{staticClass:"mb-0"},[_c('b-radio',{attrs:{"name":"resource_type","native-value":_vm.ResourceTypes.USER},model:{value:(_vm.payload.resourceType),callback:function ($$v) {_vm.$set(_vm.payload, "resourceType", $$v)},expression:"payload.resourceType"}},[_vm._v(" User(s) ")]),_c('b-radio',{attrs:{"name":"resource_type","native-value":_vm.ResourceTypes.GROUP},model:{value:(_vm.payload.resourceType),callback:function ($$v) {_vm.$set(_vm.payload, "resourceType", $$v)},expression:"payload.resourceType"}},[_vm._v(" Group(s) ")])],1):_c('label',[_vm._v(" "+_vm._s(_vm.resourceType === _vm.ResourceTypes.USER ? 'User(s)' : 'Group(s)')+" ")]),(
            _vm.payload.resourceType === _vm.ResourceTypes.USER ||
            _vm.resourceType === _vm.ResourceTypes.USER
          )?_c('SelectUserListComponent',{key:_vm.userListComponentKey,attrs:{"isMultiSelect":true,"limit":10,"selectedUsers":_vm.selectedUsers},on:{"update:selectedUsers":function($event){_vm.selectedUsers=$event},"update:selected-users":function($event){_vm.selectedUsers=$event},"selectedUser":_vm.handleSelectedResources}}):_vm._e(),(
            _vm.payload.resourceType === _vm.ResourceTypes.GROUP ||
            _vm.resourceType === _vm.ResourceTypes.GROUP
          )?_c('SelectGroupListComponent',{key:_vm.groupListComponentKey,attrs:{"isMultiSelect":true,"limit":10},on:{"selectedGroup":_vm.handleSelectedResources}}):_vm._e()],1),(
          _vm.payload.resourceType === _vm.ResourceTypes.USER ||
          _vm.resourceType === _vm.ResourceTypes.USER
        )?_c('div',{class:_vm.$style.width100},[_c('p',{class:_vm.$style.infoText},[_vm._v(" User not yet in the system? "),_c('span',{class:_vm.$style.linkText,on:{"click":function($event){return _vm.openInviteNewUserForm()}}},[_vm._v(" Invite new user ")])])]):_vm._e()]),(_vm.stepsIndex === 1)?_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.stepsIndex === 1),expression:"stepsIndex === 1"}]},[_c('InviteNewUserForm',{class:_vm.$style.containerPadding,attrs:{"products":_vm.products},on:{"formData":_vm.handleFormData,"changeDisabledButton":_vm.handleInviteButtonDisabledChange}})],1):_vm._e()]),_c('footer',{staticClass:"modal-card-foot"},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.stepsIndex === 0),expression:"stepsIndex === 0"}],staticClass:"right"},[_c('b-button',{staticStyle:{"text-decoration":"none"},attrs:{"type":"is-text"},on:{"click":function($event){return _vm.closeModal()}}},[_vm._v(" Cancel ")]),_c('b-button',{staticClass:"button is-primary",attrs:{"disabled":_vm.isButtonDisable()},on:{"click":_vm.handleAssignToRole}},[_vm._v(" Assign to role ")])],1),(_vm.stepsIndex === 1)?_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.stepsIndex === 1),expression:"stepsIndex === 1"}],staticClass:"right"},[_c('b-button',{staticStyle:{"text-decoration":"none"},attrs:{"type":"is-text"},on:{"click":function($event){return _vm.prevStep()}}},[_vm._v(" Cancel ")]),_c('b-button',{staticClass:"button is-primary",attrs:{"disabled":_vm.isInviteButtonDisable},on:{"click":_vm.handleInviteUser}},[_vm._v(" Invite ")])],1):_vm._e()])])}
var staticRenderFns = []

export { render, staticRenderFns }