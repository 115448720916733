
































































































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { Action, State } from 'vuex-class';
import { ToastProgrammatic as Toast } from 'buefy';
import { Group, User } from '../../../store/modules/admin/types/admin.types';
import {
  AddGroupsToRoleDto,
  AddUsersToRoleDto,
  GroupIdTypeName,
  ResourceTypes
} from '../../../store/modules/roles-and-permissions/types/roles-and-permissions.types';
import { RootState } from '../../../store/store';
import { ApiState } from '../../../store/types/general.types';
import SelectGroupListComponent from './SelectGroupListComponent.vue';
import SelectUserListComponent from './SelectUserListComponent.vue';
import { RoleSansTree } from '../../../store/modules/role/types/role.types';
import {
  Product,
  ApplicationItems,
  InviteUserFormValues,
  ProductInvitationDto
} from '../../../store/modules/application/types/application.types';
import InviteNewUserForm from './InviteNewUserForm.vue';
import { AxiosError } from 'axios';

@Component({
  components: {
    SelectUserListComponent,
    SelectGroupListComponent,
    InviteNewUserForm
  }
})
export default class AssignToRoleModal extends Vue {
  @Prop() public role!: RoleSansTree;
  @Prop() public resourceType!: ResourceTypes | undefined;

  public stepsIndex: number = 0;
  public isInviteButtonDisable: boolean = true;
  public selectedUsers: Array<Partial<User>> = [];
  public selectedResources: User[] | Group[] = [];
  public userListComponentKey: number = Math.floor(Math.random() * 999);
  public groupListComponentKey: number = Math.floor(Math.random() * 999);
  public newUserInvitesPayload: ProductInvitationDto[] = [];
  public payload: {
    resourceType: ResourceTypes;
    resourceIds: number[] | GroupIdTypeName[];
    roleId: number;
  } = {
    resourceType: this.resourceType ? this.resourceType : ResourceTypes.USER,
    resourceIds: [],
    roleId: this.role.id
  };
  public formData: InviteUserFormValues = {
    email: '',
    firstName: '',
    lastName: '',
    productId: 0
  };

  @Action('rolesAndPermissions/addUsersToRole')
  public addUsersToRole!: (params: {
    id: number;
    payload: AddUsersToRoleDto;
  }) => Promise<void>;

  @State(
    ({ rolesAndPermissions }: RootState) =>
      rolesAndPermissions.apiState.addUsersToRole.success
  )
  public addUsersToRoleSuccessState!: boolean;

  @State(
    ({ rolesAndPermissions }: RootState) =>
      rolesAndPermissions.apiState.addUsersToRole.error
  )
  public addUsersToRoleErrorState!: boolean;

  @State(
    ({ rolesAndPermissions }: RootState) =>
      rolesAndPermissions.apiState.addUsersToRole.error
  )
  private addUsersToRoleErrorMessage!: string | null;

  @Action('rolesAndPermissions/addGroupsToRole')
  private addGroupsToRole!: (params: {
    id: number;
    payload: AddGroupsToRoleDto;
  }) => Promise<void>;

  @State(
    ({ rolesAndPermissions }: RootState) =>
      rolesAndPermissions.apiState.addGroupsToRole.success
  )
  private addGroupsToRoleSuccessState!: boolean;

  @State(
    ({ rolesAndPermissions }: RootState) =>
      rolesAndPermissions.apiState.addGroupsToRole.error
  )
  private addGroupsToRoleErrorMessage!: string | null;

  @State(
    ({ rolesAndPermissions }: RootState) =>
      rolesAndPermissions.apiState.addGroupsToRole.error
  )
  private addGroupsToRoleErrorState!: boolean;

  @Action('application/getApplications')
  private getApplications!: () => Promise<void>;

  @State(({ application }: RootState) => application.apiState.getApplications)
  private getApplicationsState!: ApiState;

  @State(({ application }: RootState) => application.productApplications)
  private productApplications!: ApplicationItems;

  get products(): Product[] {
    return this.productApplications && this.productApplications.products
      ? this.productApplications.products.filter(
          (p) => p.isActive && !p.isDeleted
        )
      : [];
  }

  get SelectUserListComponent() {
    return SelectUserListComponent;
  }

  get SelectGroupListComponent() {
    return SelectGroupListComponent;
  }

  get ResourceTypes() {
    return ResourceTypes;
  }

  public isButtonDisable(): boolean {
    if (this.payload.resourceType === ResourceTypes.USER) {
      return (
        !this.payload.resourceIds.length && !this.newUserInvitesPayload.length
      );
    }
    if (this.payload.resourceType === ResourceTypes.GROUP) {
      return !this.payload.resourceIds.length;
    }
    return false;
  }

  public handleSelectedResources(resources: User[] | Group[]) {
    if (this.payload.resourceType === ResourceTypes.USER) {
      this.selectedResources = resources as User[];
      this.payload.resourceIds = this.selectedResources
        ? this.selectedResources.filter((r) => r.userId).map((r) => r.userId)
        : [];
      this.selectedUsers = this.selectedResources;
    }
    if (this.payload.resourceType === ResourceTypes.GROUP) {
      this.selectedResources = resources as Group[];
      this.payload.resourceIds = this.selectedResources
        ? this.selectedResources.map(
            (r) =>
              ({ groupId: r.id, typeName: r.types.name } as GroupIdTypeName)
          )
        : [];
    }
  }

  public openInviteNewUserForm(): void {
    this.stepsIndex = 1;
    this.getApplications();
  }

  public handleFormData(values: InviteUserFormValues) {
    this.formData = values;
  }

  public handleInviteButtonDisabledChange(newValue: boolean) {
    this.isInviteButtonDisable = newValue;
  }

  public handleInviteUser() {
    const { email, firstName, lastName, productId } = this.formData;
    if (email && firstName && lastName) {
      this.newUserInvitesPayload.push({
        email,
        firstName,
        lastName,
        productId
      });
      this.stepsIndex = 0;
      this.selectedUsers.push({
        email,
        firstName,
        lastName
      });
      this.userListComponentKey += 1;
      Toast.open({
        queue: true,
        position: 'is-top',
        message: `New user invitation drafted`,
        type: 'is-dark'
      });
    }
  }

  public closeModal(): void {
    this.$emit('close');
  }

  public prevStep(): void {
    this.stepsIndex -= 1;
  }

  public handleAssignToRole(): void {
    if (this.payload.resourceType === ResourceTypes.USER) {
      const payload: AddUsersToRoleDto = {
        existingUserIds: this.payload.resourceIds as number[],
        invitations: this.newUserInvitesPayload
      };
      this.addUsersToRole({ id: this.role.id, payload });
    } else if (this.payload.resourceType === ResourceTypes.GROUP) {
      const payload: AddGroupsToRoleDto = {
        payload: this.payload.resourceIds as GroupIdTypeName[]
      };
      this.addGroupsToRole({ id: this.role.id, payload });
    }
  }

  @Watch('payload.resourceType')
  onResourceTypeChange(): void {
    this.payload.resourceIds = [];
    this.selectedUsers = [];
    this.newUserInvitesPayload = [];
  }

  @Watch('addUsersToRoleSuccessState')
  public onAddUsersToRoleState(state: ApiState): void {
    if (this.addUsersToRoleSuccessState) {
      Toast.open({
        queue: true,
        position: 'is-top',
        message: `User(s) assigned to role`,
        type: 'is-dark'
      });
      this.$emit('updateUsersAssignedCount');
      this.closeModal();

      if (this.newUserInvitesPayload.length > 0) {
        Toast.open({
          queue: true,
          position: 'is-top',
          message: `User(s) invitation sent successfully`,
          type: 'is-dark'
        });
      }
    }
  }

  @Watch('addUsersToRoleErrorState')
  public watchAddUsersToRoleErrorState() {
    if (this.addUsersToRoleErrorState) {
      let message = this.addUsersToRoleErrorMessage ?? '';
      /**
       * Error message is simplified. Original message shows user IDs which is
       * not actionable data for the user.
       */
      if (this.addUsersToRoleErrorMessage?.includes('already has')) {
        message = 'User(s) already assigned to role';
      }

      Toast.open({
        queue: true,
        position: 'is-top',
        message,
        type: 'is-danger'
      });
    }
  }

  @Watch('addGroupsToRoleSuccessState')
  public onAddGroupsToRoleState(state: ApiState): void {
    if (this.addGroupsToRoleSuccessState) {
      Toast.open({
        queue: true,
        position: 'is-top',
        message: `Group(s) assigned to role`,
        type: 'is-dark'
      });
      this.$emit('updateGroupsAssignedCount');
      this.closeModal();
    }
  }

  @Watch('addGroupsToRoleErrorState')
  public watchAddGroupsToRoleErrorState() {
    if (this.addGroupsToRoleErrorState) {
      let message = this.addGroupsToRoleErrorMessage ?? '';
      if (this.addGroupsToRoleErrorMessage?.includes('already has')) {
        message = 'Group(s) already assigned to role';
      }
      Toast.open({
        queue: true,
        position: 'is-top',
        message,
        type: 'is-danger'
      });
    }
  }
}
